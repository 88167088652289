import React from 'react'
import { Link } from 'react-router-dom'
import Backgroundimage from '../../../assets/images/background/image-8.jpg';
import bg19 from '../../../assets/images/background/bg-19.png'
import bg20 from '../../../assets/images/background/bg-20.png'
import img8 from '../../../assets/images/background/image-8.jpg'


// import badgeone from '../../../assets/images/resource/badge-2.png'

// Menu - Special
import momo from '../../../assets/images/resource/menu/special/momo.jpg'
import lakhamari from '../../../assets/images/resource/menu/special/lakhamari.jpg'
import aithamari from '../../../assets/images/resource/menu/special/aithamari.jpg'
import donut from '../../../assets/images/resource/menu/special/donut.jpg'
import nimki from '../../../assets/images/resource/menu/special/nimki.jpg'
import pustakari from '../../../assets/images/resource/menu/special/pustakari.jpg'
import yomari from '../../../assets/images/resource/menu/special/yomari.jpg'
import selRoti from '../../../assets/images/resource/menu/special/sel-roti.jpg'


function Specialmenu() {
    return (
        <>
            <section className="special-offer-two">

                <div className="left-bg"><img src={bg20} alt="" title="" /></div>
                <div className="right-bg"><img src={bg19} alt="" title="" /></div>
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="subtitle"><span>special offer</span></div>

                        <div className="pattern-image">
                            <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                        </div>

                        <h2>Our Selected Special</h2>
                    </div>
                    <div className="row clearfix">

                        <div className="offer-block-three col-xl-3 col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="0ms">
                                <div className="image"><Link to="#"><img src={momo} alt="" /></Link></div>
                                <h4><Link to="#">Momo</Link></h4>
                                <div className="text desc">A popular Nepali dish consisting of steamed or fried dumplings filled with a variety of savory fillings such as ground meat (usually chicken or pork), vegetables, or cheese, flavored with a blend of Nepali spices.</div>
                            </div>
                        </div>


                        <div className="offer-block-three col-xl-3 col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">
                                <div className="image"><Link to="#"><img src={donut} alt="" /></Link></div>
                                <h4><Link to="#">Nepali Style Donut</Link></h4>
                                <div className="text desc">A classic pastry made from a dough of flour, deep-fried until golden brown and crispy on the outside and soft and fluffy on the inside</div>
                            </div>
                        </div>


                        <div className="offer-block-three col-xl-3 col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="400ms">
                                <div className="image"><Link to="#"><img src={selRoti} alt="" /></Link></div>
                                <h4><Link to="#">Sel Roti</Link></h4>
                                <div className="text desc">A traditional Nepali style dish made with a batter of rice flour. This sweet and aromatic dough is then deep-fried in oil until it forms a crispy outer layer and a soft, fluffy interior.</div>
                            </div>
                        </div>


                        <div className="offer-block-three col-xl-3 col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="600ms">
                                <div className="image"><Link to="#"><img src={nimki} alt="" /></Link></div>
                                <h4><Link to="#">Nimki</Link></h4>
                                <div className="text desc">A popular savory snack from Nepal made from a dough of flour, rolled thin and cut into diamond shapes, then deep-fried until crispy and golden brown.</div>
                            </div>
                        </div>


                        <div className="offer-block-three col-xl-3 col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="0ms">
                                <div className="image"><Link to="#"><img src={pustakari} alt="" /></Link></div>
                                <h4><Link to="#">Pustakari</Link></h4>
                                <div className="text desc">A traditional Nepali sweet made with jaggery (unrefined cane sugar), nuts and khuwa. The mixture is formed into small balls.</div>
                            </div>
                        </div>


                        <div className="offer-block-three col-xl-3 col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">
                                <div className="image"><Link to="#"><img src={lakhamari} alt="" /></Link></div>
                                <h4><Link to="#">Lakhamari</Link></h4>
                                <div className="text desc">A popular Newari confectionary made with rice flour and black lentil flour.</div>
                            </div>
                        </div>


                        <div className="offer-block-three col-xl-3 col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="400ms">
                                <div className="image"><Link to="#"><img src={yomari} alt="" /></Link></div>
                                <h4><Link to="#">Yomari</Link></h4>
                                <div className="text desc">A traditional Newari sweet dumpling made from a dough of rice flour, stuffed with a sweet filling.</div>
                            </div>
                        </div>


                        <div className="offer-block-three col-xl-3 col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="600ms">
                                <div className="image"><Link to="#"><img src={aithamari} alt="" /></Link></div>
                                <h4><Link to="#">Aithamari</Link></h4>
                                <div className="text desc">A popular Newari confectionary made with all purpose flour and sugar</div>
                            </div>
                        </div>

                    </div>
                </div>

            </section>


            {/*<section className="chef-selection">*/}

            {/*    <div className="auto-container">*/}
            {/*        <div className="outer-container">*/}
            {/*            <div className="row clearfix">*/}

            {/*                <div className="image-col col-xl-6 col-lg-6 col-md-12 col-sm-12">*/}
            {/*                    <div className="inner wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="0ms">*/}
            {/*                        <div className="image-layer" style={{ backgroundImage: `url(${Backgroundimage})` }}>*/}

            {/*                        </div>*/}
            {/*                        <div className="image"><img src={img8} alt="" /></div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}

            {/*                <div className="content-col col-xl-6 col-lg-6 col-md-12 col-sm-12">*/}
            {/*                    <div className="inner wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="0ms">*/}
            {/*                        <div className="title-box">*/}
            {/*                            <span className="badge-icon"><img src={badgeone} alt="" title="" /></span>*/}
            {/*                            <div className="subtitle"><span>chef selection</span></div>*/}

            {/*                            <div className="pattern-image">*/}
            {/*                                <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />*/}
            {/*                            </div>*/}

            {/*                            <h3>Lobster Tortellini</h3>*/}
            {/*                            <div className="text">Lorem Ipsum is simply dummy text of the printingand typesetting industry lorem Ipsum has been the industrys standard dummy text ever since.</div>*/}
            {/*                        </div>*/}
            {/*                        <div className="price"><span className="old">$40.00</span> <span className="new">$20.00</span></div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}

            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}

            {/*</section>*/}
        </>
    )
}

export default Specialmenu