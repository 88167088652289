import React from 'react'
import { Link } from 'react-router-dom';
import bg19 from '../../../assets/images/background/bg-19.png'
import bg20 from '../../../assets/images/background/bg-20.png'
import menuimg8 from '../../../assets/images/resource/menu-image-8.png'

// Menu item Appetizers
import choila from '../../../assets/images/resource/menu/appetizer/choila.jpg'
import chickenChilly from '../../../assets/images/resource/menu/appetizer/chicken-chilly.jpg'
import porkChilly from '../../../assets/images/resource/menu/appetizer/pork-chilly.jpg'
import paneerChilly from '../../../assets/images/resource/menu/appetizer/paneer-chilly.jpg'
import chickenTaas from '../../../assets/images/resource/menu/appetizer/chicken-taas.jpg'
import chickenRoast from '../../../assets/images/resource/menu/appetizer/chicken-roast.jpg'
import fishFry from '../../../assets/images/resource/menu/appetizer/fish-fry.jpg'
import vegPakora from '../../../assets/images/resource/menu/appetizer/veg-pakora.jpg'
import vegChowmein from '../../../assets/images/resource/menu/appetizer/veg-chowmein.jpg'
import piroAaloo from '../../../assets/images/resource/menu/appetizer/piro-aaloo.jpg'
import bara from '../../../assets/images/resource/menu/appetizer/bara.jpg'
import mushroomChoila from '../../../assets/images/resource/menu/appetizer/mushroom-choila.jpg'

// Menu item Main
import chickenCurry from '../../../assets/images/resource/menu/main/chicken-curry.jpg'
import goatCurry from '../../../assets/images/resource/menu/main/goat-curry.jpg'
import fishCurry from '../../../assets/images/resource/menu/main/fish-curry.jpg'
import mutterPaneer from '../../../assets/images/resource/menu/main/mutter-paneer.jpg'
import aalooTama  from '../../../assets/images/resource/menu/main/aaloo-tama.jpg'
import chanaMasala  from '../../../assets/images/resource/menu/main/chana-masala.jpg'
import rajma  from '../../../assets/images/resource/menu/main/rajma.jpg'
import dalMakhni  from '../../../assets/images/resource/menu/main/dal-makhni.jpg'
import mixVegCurry  from '../../../assets/images/resource/menu/main/mix-veg-curry.jpg'
import alooKauli  from '../../../assets/images/resource/menu/main/alu-kauli.jpg'
import tomatoCurry  from '../../../assets/images/resource/menu/main/tomato-curry.jpg'

function Appetizers() {
    return (
        <>
            <section className="menu-two">

                <div className="right-bg"><img src={bg19} alt="" title="" /></div>
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="subtitle"><span>STARTER MENU</span></div>

                        <div className="pattern-image">
                            <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                        </div>

                        <h2>Appetizers</h2>
                    </div>
                    <div className="row clearfix">
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={choila} alt="" /></Link></div>
                                        <div className="ttl clearfix"><h5><Link href="#">Choila (Chicken, Pork)</Link></h5></div>
                                        <div className="text desc"><Link href="#">A Traditional newari dish made with grilled and smoked choice of meat mixed with a blend of traditional Nepali spices.</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={chickenChilly} alt="" /></Link></div>
                                        <div className="ttl clearfix"><h5><Link href="#">Chicken Chilly</Link></h5></div>
                                        <div className="text desc"><Link href="#">Tender pieces of chicken stir-fried with onions, bell peppers, and green chilies in a spicy sauce, flavored with garlic, ginger, and soy sauce.</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={porkChilly} alt="" /></Link></div>
                                        <div className="ttl clearfix"><h5><Link href="#">Pork Chilly</Link></h5></div>
                                        <div className="text desc"><Link href="#">Tender pieces of pork stir-fried with onions, bell peppers, and green chilies in a spicy sauce, flavored with garlic, ginger, and soy sauce.</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={paneerChilly} alt="" /></Link></div>
                                        <div className="ttl clearfix"><h5><Link href="#">Paneer Chilly</Link></h5></div>
                                        <div className="text desc"><Link href="#">Cubes of paneer (cottage cheese) stir-fried with onions, bell peppers, and green chilies in a spicy sauce, flavored with garlic, ginger, and soy sauce. </Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={chickenTaas} alt="" /></Link></div>
                                        <div className="ttl clearfix"><h5><Link href="#">Chicken Taas</Link></h5></div>
                                        <div className="text desc"><Link href="#">Tender pieces of boneless chicken marinated in Nepali spices and pan-fried until crispy and golden brown.</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={chickenRoast} alt="" /></Link></div>
                                        <div className="ttl clearfix"><h5><Link href="#">Chicken Roast</Link></h5></div>
                                        <div className="text desc"><Link href="#">Tender Bone in Chicken Pieces marinated overnight in Nepali spices and deep fried until crispy and golden brown.</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={fishFry} alt="" /></Link></div>
                                        <div className="ttl clearfix"><h5><Link href="#">Fish Fry</Link></h5></div>
                                        <div className="text desc"><Link href="#">Marinated fish fillets coated in a blend of spices and fried until crispy and golden brown.</Link></div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={mushroomChoila} alt="" /></Link></div>
                                        <div className="ttl clearfix"><h5><Link href="#">Mushroom Choila </Link></h5></div>
                                        <div className="text desc"><Link href="#"> Pan Seared Fresh Mushroom mixed with a blend of traditional Nepali spices.</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={vegPakora} alt="" /></Link></div>
                                        <div className="ttl clearfix"><h5><Link href="#">Veg Pakora </Link></h5></div>
                                        <div className="text desc"><Link href="#">Mix of Fresh Vegetables (Onions, Carrots, Cabbage) mixed in Chickpeas flour and deep fried until golden brown.</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={vegChowmein} alt="" /></Link></div>
                                        <div className="ttl clearfix"><h5><Link href="#">Chowmein (Veg) </Link></h5></div>
                                        <div className="text desc"><Link href="#">Stir-fried noodles cooked with mixed vegetables, such as cabbage, carrots, bell peppers, and onions, in a savory sauce flavored with garlic, ginger, soy sauce, and chili sauce.</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={piroAaloo} alt="" /></Link></div>
                                        <div className="ttl clearfix"><h5><Link href="#">Piro Aaloo </Link></h5></div>
                                        <div className="text desc"><Link href="#">A Spicy Nepali-style Potato dish cooked in Nepali spices</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={menuimg8} alt="" /></Link></div>
                                        <div className="ttl clearfix"><h5><Link href="#">Mix Achaar </Link></h5></div>
                                        <div className="text desc"><Link href="#">A Spicy and Tangy Newari-style pickle made with Radish, carrots, potatoes blended with Nepali style.</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={bara} alt="" /></Link></div>
                                        <div className="ttl clearfix"><h5><Link href="#">Bara </Link></h5></div>
                                        <div className="text desc"><Link href="#">A Newari dish also known as Lentil Pancake made with Black split Lentil</Link></div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </section>


            <section className="menu-two">

                <div className="left-bg"><img src={bg20} alt="" title="" /></div>
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="subtitle"><span>fresh & hygine</span></div>

                        <div className="pattern-image">

                            <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />

                        </div>

                        <h2>Main Dishes</h2>
                    </div>
                    <div className="row clearfix">
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={chickenCurry} alt="" /></Link></div>
                                        <div className="ttl clearfix"><h5><Link href="#">Chicken Curry </Link></h5></div>
                                        <div className="text desc"><Link href="#">Bone in chicken cooked in a flavorful sauce, and a blend of aromatic spices.</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={goatCurry} alt="" /></Link></div>
                                        <div className="ttl clearfix"><h5><Link href="#">Mutton/Goat Curry </Link></h5></div>
                                        <div className="text desc"><Link href="#"> Tender pieces of skin-on goat meat slow-cooked in a rich and aromatic sauce made with a blend of Nepali spices.</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={fishCurry} alt="" /></Link></div>
                                        <div className="ttl clearfix"><h5><Link href="#">Fish Curry </Link></h5></div>
                                        <div className="text desc"><Link href="#">Fresh fish fillets cooked in a fragrant and aromatic sauce with a blend of Nepali spices.</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={mixVegCurry} alt="" /></Link></div>
                                        <div className="ttl clearfix"><h5><Link href="#">Mix Veg Curry </Link></h5></div>
                                        <div className="text desc"><Link href="#">A flavorful and colorful vegetarian dish made with a mix of fresh seasonal vegetables, cooked in a fragrant and spicy sauce and a blend of aromatic spices.</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={mutterPaneer} alt="" /></Link></div>
                                        <div className="ttl clearfix"><h5><Link href="#">Mutter Paneer </Link></h5></div>
                                        <div className="text desc"><Link href="#">A classic dish made with cubes of paneer (cottage cheese) and green peas cooked in a rich and creamy tomato-based sauce, flavored with a blend of aromatic spices.</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={alooKauli} alt="" /></Link></div>
                                        <div className="ttl clearfix"><h5><Link href="#">Aloo Kauli </Link></h5></div>
                                        <div className="text desc"><Link href="#">A Traditional Nepali dish consisting of potatoes and cauliflower cooked in a flavorful blend of spices.</Link></div>
                                    </div>
                                </div>



                            </div>
                        </div>
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={aalooTama} alt="" /></Link></div>
                                        <div className="ttl clearfix"><h5><Link href="#">Aaloo Taama </Link></h5></div>
                                        <div className="text desc"><Link href="#"> A traditional Newari dish from Nepal made with potatoes, bamboo shoots, and black-eyed beans cooked in a spicy and sour soup, flavored with a blend of aromatic spices. </Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={tomatoCurry} alt="" /></Link></div>
                                        <div className="ttl clearfix"><h5><Link href="#">Tomato Curry </Link></h5></div>
                                        <div className="text desc"><Link href="#">A Typical Nepali dish made of fresh tomato with choice of paneer or soy to add cooked with a blend of aromatic spices.</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={menuimg8} alt="" /></Link></div>
                                        <div className="ttl clearfix"><h5><Link href="#">Aalo Kerau </Link></h5></div>
                                        <div className="text desc"><Link href="#">A traditional Nepali vegetarian dish made with potatoes and green peas cooked in a flavorful and aromatic sauce and a blend of Nepali spices. </Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={chanaMasala} alt="" /></Link></div>
                                        <div className="ttl clearfix"><h5><Link href="#">Chana Masala </Link></h5></div>
                                        <div className="text desc"><Link href="#">A popular vegetarian dish from Nepal made with chickpeas cooked in a flavorful and tangy tomato-based sauce, seasoned with a blend of aromatic spices. </Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={rajma} alt="" /></Link></div>
                                        <div className="ttl clearfix"><h5><Link href="#">Rajma </Link></h5></div>
                                        <div className="text desc"><Link href="#">A flavorful vegetarian dish made with red kidney beans cooked in a rich and aromatic sauce and a blend of Nepali spices.</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={dalMakhni} alt="" /></Link></div>
                                        <div className="ttl clearfix"><h5><Link href="#">Dal Makhni </Link></h5></div>
                                        <div className="text desc"><Link href="#">A creamy and flavorful vegetarian dish made with black lentils and kidney beans, slow-cooked in a rich and aromatic sauce and a blend of Nepali spices.</Link></div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default Appetizers