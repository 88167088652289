import React from 'react'
import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel2';


// Menu - Special
import aithamari from '../../assets/images/resource/menu/special/aithamari.jpg'
import donut from '../../assets/images/resource/menu/special/donut.jpg'
import lakhamari from '../../assets/images/resource/menu/special/lakhamari.jpg'
import momo from '../../assets/images/resource/menu/special/momo.jpg'
import nimki from '../../assets/images/resource/menu/special/nimki.jpg'
import pustakari from '../../assets/images/resource/menu/special/pustakari.jpg'
import selRoti from '../../assets/images/resource/menu/special/sel-roti.jpg'
import yomari from '../../assets/images/resource/menu/special/yomari.jpg'


function Special() {
    const options = {
        items: 8,
        rewind: true,
        loop: true,
        margin: 45,
        nav: true,
        smartSpeed: 700,
        autoplay: true,
        autoplayTimeout: 7000,
        navText: ['<span class="icon fa-light fa-angle-left"></span>', '<span class="icon fa-light fa-angle-right"></span>'],
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            768: {
                items: 2,
                margin: 30
            },
            992: {
                items: 3,
                margin: 30
            },
            1200: {
                items: 4
            }
        }
    };

    return (

      <>
          <section className="special-offer">
              <div className="outer-container">

                  <div className="auto-container">

                      <div className="title-box centered">
                          <div className="subtitle"><span> special offer </span></div>

                          <div className="pattern-image">
                              <img src={require('../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                          </div>

                          <h2> Best Specialties </h2>
                      </div>

                      <OwlCarousel className="dish-gallery-slider owl-carousel owl-theme" options={options}>

                          <div className="offer-block-two">
                              <div className="inner-box">
                                  <div className="image"><Link to="#"><img src={momo} alt="" /></Link></div>
                                  <h4><Link to="#">Momo</Link></h4>
                                  <div className="text desc">A popular Nepali dish consisting of steamed or fried dumplings filled with a variety of savory fillings such as ground meat (usually chicken or pork), vegetables, or cheese, flavored with a blend of Nepali spices.</div>
                              </div>
                          </div>

                          <div className="offer-block-two margin-top">
                              <div className="inner-box">
                                  <div className="image"><Link to="#"><img src={donut} alt="" /></Link></div>
                                  <h4><Link to="#">Nepali Style Donut</Link></h4>
                                  <div className="text desc">A classic pastry made from a dough of flour, deep-fried until golden brown and crispy on the outside and soft and fluffy on the inside</div>
                              </div>
                          </div>

                          <div className="offer-block-two">
                              <div className="inner-box">
                                  <div className="image"><Link to="#"><img src={selRoti} alt="" /></Link></div>
                                  <h4><Link to="#">Sel Roti</Link></h4>
                                  <div className="text desc">A traditional Nepali style dish made with a batter of rice flour. This sweet and aromatic dough is then deep-fried in oil until it forms a crispy outer layer and a soft, fluffy interior.</div>
                              </div>
                          </div>

                          <div className="offer-block-two margin-top">
                              <div className="inner-box">
                                  <div className="image"><Link to="#"><img src={nimki} alt="" /></Link></div>
                                  <h4><Link to="#">Nimki</Link></h4>
                                  <div className="text desc">A popular savory snack from Nepal made from a dough of flour, rolled thin and cut into diamond shapes, then deep-fried until crispy and golden brown.</div>
                              </div>
                          </div>


                          <div className="offer-block-two">
                              <div className="inner-box">
                                  <div className="image"><Link to="#"><img src={pustakari} alt="" /></Link></div>
                                  <h4><Link to="#">Pustakari</Link></h4>
                                  <div className="text desc">A traditional Nepali sweet made with jaggery (unrefined cane sugar), nuts and khuwa. The mixture is formed into small balls.</div>
                              </div>
                          </div>


                          <div className="offer-block-two margin-top">
                              <div className="inner-box">
                                  <div className="image"><Link to="#"><img src={lakhamari} alt="" /></Link></div>
                                  <h4><Link to="#">Lakhamari</Link></h4>
                                  <div className="text desc">A popular Newari confectionary made with rice flour and black lentil flour.</div>
                              </div>
                          </div>


                          <div className="offer-block-two">
                              <div className="inner-box">
                                  <div className="image"><Link to="#"><img src={yomari} alt="" /></Link></div>
                                  <h4><Link to="#">Yomari</Link></h4>
                                  <div className="text desc">A traditional Newari sweet dumpling made from a dough of rice flour, stuffed with a sweet filling.</div>
                              </div>
                          </div>


                          <div className="offer-block-two margin-top">
                              <div className="inner-box">
                                  <div className="image"><Link to="#"><img src={aithamari} alt="" /></Link></div>
                                  <h4><Link to="#">Aithamari</Link></h4>
                                  <div className="text desc">A popular Newari confectionary made with all purpose flour and sugar</div>
                              </div>
                          </div>


                      </OwlCarousel>

                      <div className="lower-link-box text-center">
                          <Link to="/menu" className="theme-btn btn-style-two clearfix">
                                <span className="btn-wrap">
                                    <span className="text-one">view all menu</span>
                                    <span className="text-two">view all menu</span>
                                </span>
                          </Link>
                      </div>

                  </div>

              </div>
          </section>
      </>

    )
}

export default Special