import React from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';

import Slideone from '../../assets/images/main-slider/slider-1.jpg';
import SlideTwo from '../../assets/images/main-slider/hero.png';
import SlideThree from '../../assets/images/main-slider/slider-3.jpg';
import SliderFour from '../../assets/images/main-slider/hero-2.png';
import SliderFive from '../../assets/images/main-slider/hero-4.png';

function Banner() {
    const heroSliders = [Slideone, SlideTwo, SlideThree, SliderFour, SliderFive];
    const randomIndex = Math.floor(Math.random() * heroSliders.length);
    const randomHeroSlider = heroSliders[randomIndex];

    const tagLines = [
        {
            title: <span>Flavors Inspired by <br />the Seasons</span>,
            tagLine: 'Enjoy & feel the joy of mouthwatering food'
        },
        {
            title: <span>The true flavor of tradition</span>,
            tagLine: 'Enjoy & feel the joy of mouthwatering food'
        },
        {
            title: <span>Taste the tradition in every bite</span>,
            tagLine: 'Enjoy & feel the joy of mouthwatering food'
        }
    ];

    const randomTagLineIndex = Math.floor(Math.random() * tagLines.length);
    const randomTagLine = tagLines[randomTagLineIndex];

    return (
        <>

            <Swiper className="banner-section"
                loop={true}
                spaceBetween={50}
                slidesPerView={1}
                navigation={{
                    nextEl: '.swiper-button-prev',
                    prevEl: '.swiper-button-next',
                  }}>

                <div className="banner-container">
                    <div className="banner-slider" >
                        <div className="swiper-wrapper">

                            <SwiperSlide>
                                <div className="swiper-slide slide-item">

                                    <div className="image-layer" style={{ backgroundImage: `url(${randomHeroSlider})` }}></div>

                                    <div className="auto-container">
                                        <div className="content-box">
                                            <div className="content">
                                                <div className="clearfix">
                                                    <div className="inner">
                                                        <div className="subtitle"><span>delightful experience</span></div>

                                                        <div className="pattern-image">

                                                            <img src={require('../../assets/images/icons/separator.svg').default} alt='mySvgImage' />

                                                        </div>

                                                        <h1>{randomTagLine.title}</h1>
                                                        <div className="text">{randomTagLine.tagLine}</div>
                                                        <div className="links-box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                                            <div className="link">
                                                                <Link to="menu" className="theme-btn btn-style-two clearfix">
                                                                    <span className="btn-wrap">
                                                                        <span className="text-one">view our menu</span>
                                                                        <span className="text-two">view our menu</span>
                                                                    </span>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="swiper-slide slide-item">
                                    <div className="image-layer" style={{ backgroundImage: `url(${randomHeroSlider})` }}></div>
                                    <div className="auto-container">
                                        <div className="content-box">
                                            <div className="content">
                                                <div className="clearfix">
                                                    <div className="inner">
                                                        <div className="subtitle"><span>delightful experience</span></div>
                                                        <div className="pattern-image">
                                                            <img src={require('../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                                                        </div>
                                                        <h1><span>The true flavor of tradition</span></h1>
                                                        <div className="text">Enjoy & feel the joy of mouthwatering food</div>
                                                        <div className="links-box clearfix">
                                                            <div className="link">
                                                                <Link to="/menu" className="theme-btn btn-style-two clearfix">
                                                                    <span className="btn-wrap">
                                                                        <span className="text-one">view our menu</span>
                                                                        <span className="text-two">view our menu</span>
                                                                    </span>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="swiper-slide slide-item">
                                    <div className="image-layer" style={{ backgroundImage: `url(${randomHeroSlider})` }}></div>
                                    <div className="auto-container">
                                        <div className="content-box">
                                            <div className="content">
                                                <div className="clearfix">
                                                    <div className="inner">
                                                        <div className="subtitle"><span>delightful experience</span></div>
                                                        <div className="pattern-image">
                                                            <img src={require('../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                                                        </div>
                                                        <h1><span>Taste the tradition in every bite</span></h1>
                                                        <div className="text">Enjoy & feel the joy of mouthwatering food</div>
                                                        <div className="links-box clearfix">
                                                            <div className="link">
                                                                <Link to="/menu" className="theme-btn btn-style-two clearfix">
                                                                    <span className="btn-wrap">
                                                                        <span className="text-one">view our menu</span>
                                                                        <span className="text-two">view our menu</span>
                                                                    </span>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>


                        </div>

                        <div className="swiper-button-prev"><span className="fal fa-angle-left"></span></div>
                        <div className="swiper-button-next"><span className="fal fa-angle-right"></span></div>


                    </div>
                </div>

            </Swiper>

        </>
    )
}

export default Banner