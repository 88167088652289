import React from 'react'

// Components
import Banner from '../Menhome/Banner'
import Offer from '../Menhome/Offer'
import Reacntupdate from '../Menhome/Recntupdates'
import Special from '../Menhome/Special'
import Story from '../Menhome/Story'
import Whyus from '../Menhome/Whyus'

// TODO: Uncomment to use the component
// import Specialdish from '../Menhome/Specialdish'
// import Videosection from '../Menhome/Videosection'
// import Teem from '../Menhome/Teem'
// import Contact from '../Menhome/Contact'
// import Menusection from '../Menhome/Menusection'

function Main() {
    return (

        <>
            <div className="menu-backdrop"> </div>
            <Banner />
            {/*<Offer />*/}
            <Story />
            {/*<Specialdish />*/}
            {/*<Menusection />*/}
            <Special />
            {/*<Contact />*/}
            <Whyus />
            {/*<Teem />*/}
            {/*<Videosection />*/}
            {/*<Reacntupdate />*/}
        </>

    )
}

export default Main